<template>
  <div>
    <v-navigation-drawer width="100%" style="height: 93vh;">
      <v-toolbar flat>
        <v-toolbar-title>Centros</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn rounded color="primary" :to="{name:'places-new'}">Nuevo</v-btn>
      </v-toolbar>
      <v-card flat class="mx-auto scroll">
        <v-skeleton-loader
          :loading="getPlacesIsLoading"
          :transition="transition"
          height="94"
          type="list-item-avatar-two-line">
          <v-list two-line dense subheader>
            <v-list-item-group active-class="primary--text">
              <template v-for="(place, index) in getPlacesPlaces">
                <v-list-item :key="place.id" @click="placeClicked(place)">
                  <template v-slot:default="">
                    <v-list-item-content>
                      <v-list-item-title v-text="place.name"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item>
                <v-divider v-if="index + 1 < getPlacesPlaces.length" :key="index"></v-divider>
              </template>
            </v-list-item-group>
          </v-list>
        </v-skeleton-loader>
      </v-card>
    </v-navigation-drawer>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { dateFromNow } from '@/mixins'
import { to } from '@/helpers'
import { PLACES_REMOVE_SELECTED_PLACE, PLACES_SET_IS_EDITING_PLACE } from '@/store/mutations.type'
import { PLACES_GET_PLACE } from '@/store/actions.type'

export default {
  name: 'PlacesSidebar',
  props: {},
  data() {
    return {
      transition: 'fade-transition'
    }
  },
  computed: {
    ...mapGetters([
      'getPlacesPlaces',
      'getPlacesIsLoading',
      'getPlacesIsSelected',
      'getPlaceSelected'
    ])
  },
  methods: {
    placeClicked(place) {
      this.$store.commit(PLACES_SET_IS_EDITING_PLACE, false)
      this.getPlace(place.id)
    },
    onNew() {},
    back() {
      this.$store.commit(PLACES_REMOVE_SELECTED_PLACE)
    },
    async getPlace(placeId) {
      const [err] = await to(
        this.$store.dispatch(PLACES_GET_PLACE, placeId)
      )
      if (err) {
        this.error = err
        this.isLoading = false
      }
      this.isLoading = false
    }
  },
  components: {},
  mixins: [dateFromNow]
}
</script>
