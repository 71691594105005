<template>
  <v-row no-gutters>
    <v-col cols="3" md="3" xl="2">
      <s-places-sidebar></s-places-sidebar>
    </v-col>
    <v-col>
      <s-places-map></s-places-map>
    </v-col>
    <v-col cols="3" md="3" xl="2" v-if="getPlacesPlace.id && getPlacesIsEditingPlace === false">
      <s-place-sidebar></s-place-sidebar>
    </v-col>
    <v-col cols="3" md="3" xl="2" v-if="getPlacesPlace.id && getPlacesIsEditingPlace">
      <s-places-sidebar-edit></s-places-sidebar-edit>
    </v-col>
  </v-row>
</template>
<script>
import { to } from '@/helpers'
import SPlacesMap from '@/components/app/places/s-places-map'
import SPlacesSidebar from '@/components/app/places/s-places-sidebar'
import SPlaceSidebar from '@/components/app/places/s-place-sidebar'
import SPlacesSidebarEdit from '@/components/app/places/s-places-sidebar-edit'

import { PLACES_GET_PLACES, SETTINGS_HIDE_SIDEBAR } from '@/store/actions.type'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Places',
  props: {},
  mounted() {
    this.getPlaces()
    this.hideSideBar()
  },
  data() {
    return {
      isLoading: true,
      error: null
    }
  },
  computed: {
    ...mapGetters(['getPlacesPlaces', 'getPlacesPlace', 'getPlacesIsEditingPlace'])
  },
  methods: {
    ...mapActions({
      hideSideBar: SETTINGS_HIDE_SIDEBAR
    }),
    async getPlaces() {
      const [err] = await to(this.$store.dispatch(PLACES_GET_PLACES))
      if (err) {
        this.error = err
        this.isLoading = false
      }
      this.isLoading = false
    }
  },
  components: { SPlacesMap, SPlacesSidebar, SPlaceSidebar, SPlacesSidebarEdit }
}
</script>
