<template>
  <div style="width: 100%">
    <v-navigation-drawer width="100%" style="height: 100vh">
      <v-card dense flat class="mt-2 mb-0">
        <v-toolbar flat dense>
          <v-btn icon @click="back">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-toolbar-title>{{name}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click.stop="onDelete()">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-toolbar>
      </v-card>
      <v-form ref="form" class="ma-4">
        <v-row justify="center" v-show="error">
          <base-error :error="error"></base-error>
        </v-row>
        <v-row no-gutters dense class="mb-2 flex-column">
          <v-col>
            <v-text-field
              outlined
              label="Nombre"
              v-model="name"
              @keydown="onNameChange"
              :loading="nameLoading"
            ></v-text-field>
            <v-text-field
              outlined
              label="Descripción"
              v-model="description"
              @keydown="onDescriptionChange"
              :loading="descriptionLoading"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters dense class="mb-2 flex-column">
          <v-col>
            <v-autocomplete
              label="Agregar usuario"
              outlined
              :items="sortedUsers"
              :filter="customFilter"
              item-text="displayName"
              v-model="mutableUser"
              @change="onUserChange"
              return-object
            ></v-autocomplete>
          </v-col>
          <v-col>
            <template v-if="placeUsers.length > 0">
              <v-list>
                <v-subheader>Usuarios asignados</v-subheader>
                <template v-for="(user, index) in sortedPlaceUsers">
                  <v-list-item :key="index">
                    <v-list-item-content class="py-0">
                      <v-list-item-title v-text="user.displayName"></v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action class="my-0">
                      <v-btn icon @click="removeUser(user)">
                        <v-icon color="grey lighten-1">mdi-close</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </template>
              </v-list>
            </template>
            <template v-else>
              <div class="black--text">Aún no hay usuarios asignados</div>
            </template>
          </v-col>
        </v-row>
      </v-form>
    </v-navigation-drawer>
    <v-dialog v-model="dialog" persistent max-width="480">
      <v-card>
        <v-card-title class="headline">¿Eliminar {{name}}?</v-card-title>
        <v-card-text>Toda la información relacionada será eliminada</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" text @click="dialog = false">Cancelar</v-btn>
          <v-btn color="primary darken-1" text @click="deletePlace">Eliminar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar">
      El lugar ha sido actualizado
      <v-btn color="success" text @click="snackbar = false">Cerrar</v-btn>
    </v-snackbar>
  </div>
</template>
<script>
import { to, debounce } from '@/helpers'
import { mapGetters } from 'vuex'
import { dateFromNow } from '@/mixins'
// import SPlacesSidebarPlaceDetail from '@/components/app/places/s-places-sidebar-place-detail'
import { PLACES_SET_SELECTED_PLACE, PLACES_SET_IS_EDITING_PLACE } from '@/store/mutations.type'
import {
  PLACES_DELETE_USER,
  PLACES_ADD_USER,
  PLACES_UPDATE_PLACE,
  PLACES_DELETE_PLACE
} from '@/store/actions.type'
export default {
  name: 'PlacesSidebar',
  props: {},
  data() {
    return {
      dialog: false,
      snackbar: false,
      error: null,
      mutableUser: null,
      placeUsers: [],
      placeId: null,
      name: '',
      nameLoading: false,
      descriptionLoading: false,
      description: '',
      transition: 'fade-transition'
    }
  },
  computed: {
    sortedPlaceUsers() {
      return this.placeUsers.slice().sort((a, b) => (a.displayName > b.displayName) ? 1 : -1)
    },
    sortedUsers() {
      let users = this.getUsers
      this.sortedPlaceUsers.forEach(placeUser => {
        users = users.filter(user => user.id !== placeUser.id)
      })
      return users.slice().sort((a, b) => (a.displayName > b.displayName) ? 1 : -1)
    },
    ...mapGetters([
      'getPlacesPlaces',
      'getPlacesIsLoading',
      'getPlacesIsSelected',
      'getPlacesPlace',
      'getUsers'
    ])
  },
  mounted() {
    this.name = this.getPlacesPlace.name
    this.description = this.getPlacesPlace.description
    this.placeId = this.getPlacesPlace.id
    this.placeUsers = this.getPlacesPlace.users
  },
  methods: {
    async onUserChange(user) {
      this.$nextTick(() => {
        this.mutableUser = null
      })
      this.error = null
      const [err] = await to(
        this.$store.dispatch(PLACES_ADD_USER, {
          userId: user.id,
          placeId: this.placeId
        })
      )
      if (!err) {
        this.placeUsers.push(user)
        this.snackbar = true
      } else {
        this.error = err
      }
    },
    async removeUser(user) {
      this.error = null

      const [err] = await to(
        this.$store.dispatch(PLACES_DELETE_USER, {
          userId: user.id,
          placeId: this.placeId
        })
      )

      if (err) {
        this.error = err
      } else {
        let index = -1

        for (let i = 0; i < this.placeUsers.length; i++) {
          if (this.placeUsers[i].id === user.id) {
            index = i
            break
          }
        }

        this.placeUsers.splice(index, 1)
        this.snackbar = true
      }
    },
    matchesId(el, id) {
      return el.id === id
    },
    customFilter(item, queryText, itemText) {
      const textOne = item.displayName.toLowerCase()
      const searchText = queryText.toLowerCase()
      return textOne.indexOf(searchText) > -1
    },
    placeClicked(place) {
      this.$store.commit(PLACES_SET_SELECTED_PLACE, place)
    },
    back() {
      this.$store.commit(PLACES_SET_IS_EDITING_PLACE, false)
    },
    onNameChange: debounce(async function() {
      this.nameLoading = true
      await this.update({ placeId: this.placeId, name: this.name })
      this.nameLoading = false
    }, 500),
    onDescriptionChange: debounce(async function() {
      this.descriptionLoading = true
      await this.update({
        placeId: this.placeId,
        description: this.description
      })
      this.descriptionLoading = false
    }, 500),
    async update(place) {
      this.error = null
      const [err] = await to(this.$store.dispatch(PLACES_UPDATE_PLACE, place))
      if (err) {
        this.error = err
      } else {
        this.snackbar = true
      }
    },
    onDelete(field) {
      this.dialog = true
    },
    async deletePlace() {
      this.dialog = false
      this.error = null
      const [err] = await to(
        this.$store.dispatch(PLACES_DELETE_PLACE, this.placeId)
      )
      if (err) {
        this.error = err
      } else {
        this.$router.push('/places')
          .catch(error => {
            error = ''
            this.$router.go('/places')
          })
      }
    }
  },
  // components: { SPlacesSidebarPlaceDetail },
  mixins: [dateFromNow]
}
</script>
