<template>
  <div>
    <v-navigation-drawer width="100%" style="height: 93vh;">
      <v-toolbar flat v-if="!getPlacesIsLoading">
        <v-toolbar-title>{{getPlacesPlace.name}}</v-toolbar-title>
        <v-btn style="margin-left: 1rem" rounded color="primary" @click="onEdit(getPlacesPlace)">
          Editar
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn icon @click="onCloseClicked">
          <v-icon>mdi-close-circle</v-icon>
        </v-btn>
      </v-toolbar>
      <v-skeleton-loader
        :loading="getPlacesIsLoading"
        :transition="transition"
        height="94"
        type="article"
      >
        <v-card flat class="mx-auto">
          <v-card-text>
            <v-row class="mx-0 mb-4 flex-column">
              <div class="mb-2 grey--text">Descripción</div>
              <div class="black--text">{{getPlacesPlace.description}}</div>
            </v-row>
            <v-row class="mx-0 mb-4 flex-column">
              <div class="mb-2 grey--text">Usuarios</div>
              <template v-if="getPlacesPlace.users.length > 0">
                <div v-for="(user, index) in getPlacesPlace.users" :key="index">
                  <div class="black--text mb-3">{{user.displayName}}</div>
                </div>
              </template>
              <template v-else>
                <div class="black--text">Aún no hay usuarios asignados</div>
              </template>
            </v-row>
          </v-card-text>
        </v-card>
      </v-skeleton-loader>
    </v-navigation-drawer>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { dateFromNow } from '@/mixins'
import { PLACES_REMOVE_SELECTED_PLACE, PLACES_SET_IS_EDITING_PLACE } from '@/store/mutations.type'
export default {
  name: 'PlaceSidebar',
  props: {},
  data() {
    return {
      img: null,
      transition: 'fade-transition'
    }
  },
  computed: {
    ...mapGetters(['getPlacesIsLoading', 'getPlacesPlace'])
  },
  components: {},
  methods: {
    back() {
      this.$router.push('/places')
    },
    onEdit(place) {
      this.$store.commit(PLACES_SET_IS_EDITING_PLACE, true)
    },
    onCloseClicked() {
      this.$store.commit(PLACES_REMOVE_SELECTED_PLACE)
    }
  },
  mixins: [dateFromNow]
}
</script>
