<template>
  <GmapMap
    ref="gmap"
    :center="center"
    v-show="!getPlacesLoading"
    :zoom="11"
    :options="options"
    style="width: 100%; height: 100%">
      <GmapMarker
        :key="place.id"
        v-for="(place) in getPlacesPlaces"
        :position="place.position"
        :shape="shape"
        @click="selectedPlace(place)"
      />
      <gmap-polygon
        v-if="getPlacesPlace.paths"
        :paths="getPlacesPlace.paths"
        :editable="false"
        :options="polygonOptions"
        @click="selectedPlace(place)">
      </gmap-polygon>
  </GmapMap>
</template>
<script>
import AppConfig from '@/constants/app-config'
import { to } from '@/helpers'
import { PLACES_GET_PLACE } from '@/store/actions.type'
import { PLACES_SET_IS_EDITING_PLACE } from '@/store/mutations.type'
import { gmapApi } from 'vue2-google-maps'
import { mapGetters } from 'vuex'

export default {
  name: 'PlacesMap',
  props: {},
  data() {
    return {
      options: {
        strokeColor: '#460A23',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#460A23',
        fillOpacity: 0.35,
        clickableIcons: true,
        mapTypeControl: true,
        fullscreenControl: true,
        streetViewControl: true,
        zoomControl: true
      },
      withUserOptions: {
        clickableIcons: false,
        strokeColor: '#222222',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#222222',
        fillOpacity: 0.35
      },
      withoutUserOptions: {
        clickableIcons: false,
        strokeColor: '#ff7600',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#ff7600',
        fillOpacity: 0.35
      },
      center: {
        lat: 19.4101,
        lng: -99.1699
      },
      shape: {
        coords: [25, 25, 25],
        type: 'circle'
      },
      urlIcon: AppConfig.urlIcon,
      publicPath: process.env.BASE_URL
    }
  },
  computed: {
    google: gmapApi,
    ...mapGetters([
      'getPlacesPlaces',
      'getPlacesPoints',
      'getPlacesPolygons',
      'getPlacesIsLoading',
      'getPlacesPlace'
    ])
  },
  methods: {
    getMarkerIcon (place) {
      if (place.location.geometry.type === 'Point') {
        return {
          url: this.urlIcon,
          size: { width: 48, height: 50, f: 'px', b: 'px' },
          scaledSize: { width: 48, height: 50, f: 'px', b: 'px' },
          shape: this.shape
        }
      }
    },
    selectedPlace(place) {
      this.$store.commit(PLACES_SET_IS_EDITING_PLACE, false)
      this.getPlace(place.id)
    },
    getOptions(place) {
      if (place.users.length < 1) {
        return this.withoutUserOptions
      } else {
        return this.withUserOptions
      }
    },
    async getPlace(placeId) {
      const [err] = await to(
        this.$store.dispatch(PLACES_GET_PLACE, placeId)
      )
      if (err) {
        this.error = err
        this.isLoading = false
      }
      this.isLoading = false
    }
  },
  watch: {
    getPlacesPlaces: function () {
      this.$refs.gmap.$mapPromise.then(map => {
        if (this.getPlacesPlaces.length > 0) {
          const bounds = new this.google.maps.LatLngBounds()

          if (this.getPlacesPlaces.length < 1) {
            bounds.extend({
              lat: 19.4326,
              lng: -99.1332
            })
          } else {
            for (const position of this.getPlacesPlaces) {
              const geo = position.location.geometry
              if (
                geo &&
                geo.type &&
                geo.type === 'Point'
              ) {
                bounds.extend({
                  lat: position.position.lat,
                  lng: position.position.lng
                })
              } else {
                // Polygon
                for (const path of position.paths[0]) {
                  bounds.extend({
                    lat: path.lat,
                    lng: path.lng
                  })
                }
              }
            }
          }

          this.center = bounds.getCenter()
          map.fitBounds(bounds)
        }
      })
    },
    getPlacesPlace: function () {
      this.$refs.gmap.$mapPromise.then(map => {
        const bounds = new this.google.maps.LatLngBounds()
        const geo = this.getPlacesPlace.location.geometry

        if (
          geo &&
          geo.type &&
          geo.type === 'Point'
        ) {
          bounds.extend({
            lat: this.getPlacesPlace.position.lat,
            lng: this.getPlacesPlace.position.lng
          })
        } else {
          // Polygon
          for (const path of this.getPlacesPlace.paths[0]) {
            bounds.extend({
              lat: path.lat,
              lng: path.lng
            })
          }
        }

        this.center = bounds.getCenter()
        map.fitBounds(bounds)
      })
    }
  }
}
</script>
